import React from "react";

import "./assets/style.scss";
import { Link } from "@reach/router";

function Button({ text, disabled, doAction, to, type, className }) {
  if (to) {
    return (
      <Link
        className={`play-button  ${disabled ? "disabled" : ""} ${className}`}
        to={to}
      >
        {text}
      </Link>
    );
  }
  return (
    <button
      className={`play-button  ${disabled ? "disabled" : ""} ${className}`}
      type={type ? type : "button"}
      onClick={doAction}
    >
      {text}
    </button>
  );
}

export default Button;
