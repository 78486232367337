export const faq = [
  {
    question: "Comment parteciper au concours ?",
    answer:
      "Vous devez être agé de plus de 18ans, résider en France et effectuer un achat via l'application My Retailer ou sur MyRetailer.com ou dans l'un des magasins MyRetailer indiqués dans le règlement. Pour participer, il est indispensable d'acheter les produits indiqués dans le règlement pendant la période indiquée",
  },
  {
    question: "Combien de fois puis-je participer au concours ?",
    answer:
      "Chaque commande ou achat effectué ne donne droit qu'à une seule chance de participer, quel que soit le nombre de produits partenaires achetés.",
  },
  {
    question: "Que puis-je gagner ?",
    answer:
      "Un nombre limité de bons d'achat est mis en jeu, comme indiqué dans le règlement du concours, d'une valeur égale à la dépense gagnante, dans la limite d'un plafond prédéfini.",
  },
  {
    question: "Comment savoir si j’ai gagné ?",
    answer:
      "Une fois que vous avez cliqué sur le bouton du jeu et activé la machine à sous virtuelle, vous verrez un message contenant immédiatement le résultat de votre participation et indiquant si vous avez gagné ou non l'un des prix.",
  },
  {
    question: "Quand recevrai-je mon prix ?",
    answer:
      "Si vous avez gagné et que vous avez consulté le message correspondant, vous recevrez votre prix dans les semaines suivantes, sous réserve de la vérification de la validité de votre participation. Le prix vous sera envoyé en format numérique à l'adresse e-mail indiquée lors de votre inscription sur l'application My Retailer ou sur MyRetailer.com si vous avez fait vos courses en ligne, ou vous sera demandé après avoir gagné si vous avez fait vos courses en point de vente.",
  },
  {
    question:
      "Que se passe-t-il si j’annule tout ou partie de mon achat après avoir gagné un prix ?",
    answer:
      "Toute annulation totale ou partielle, pour quelque raison que ce soit, de l'achat effectué entraînera l'annulation de la participation au concours et, éventuellement, l'annulation du prix gagné : dans ce cas, le prix ne sera pas reconnu.",
  },
  {
    question:
      "Puis-je modifier ma commande après avoir gagné (par exemple pour ajouter ou remplacer des produits) ?",
    answer:
      "Non, toute modification de votre commande, même partielle, entraînera l'annulation de votre gain.",
  },
  {
    question: "Comment utiliser mon gain ?",
    answer:
      "Lors de la réception de votre bon d'achat, vous recevrez également toutes les informations nécessaires à son utilisation, y compris les éventuelles limitations et la durée de validité.",
  },
];
