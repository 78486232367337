import React, { useState } from "react";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import RadioButton from "../../components/Form/Radio/RadioButton";
import { refundPages } from "./RefundPagesEnum";
import {
  crypteItem,
  getOfferId,
  getRetailerId,
  sendMailLaterRefund,
} from "../../../utils/catalinaRequests";
import {
  enableIban,
  enableLater,
  enablePaypal,
} from "../../../utils/clientConfig";
import { useLocation } from "@reach/router";

function RefundChoices({
  selectPage,
  laterChoice = true,
  backgroundLayerStyle,
}) {
  const { t } = useTranslation("message");
  const [bank, setBank] = useState(false);
  const [paypal, setPaypal] = useState(false);
  const [later, setLater] = useState(false);
  let location = useLocation();

  function chooseRefund(choice) {
    switch (choice) {
      case refundPages.BANK:
        setBank(true);
        setPaypal(false);
        setLater(false);
        break;
      case refundPages.PAYPAL:
        setBank(false);
        setPaypal(true);
        setLater(false);
        break;
      case refundPages.LATER:
        setBank(false);
        setPaypal(false);
        setLater(true);
        break;
      default:
        setBank(false);
        setPaypal(false);
        setLater(false);
    }
  }
  function navigateToChoice() {
    if (bank) {
      selectPage(refundPages.BANK);
    }
    if (paypal) {
      selectPage(refundPages.PAYPAL);
    }
    if (later) {
      let email = sessionStorage.getItem("email");
      let cryptedHolderRef = encodeURIComponent(crypteItem(email));
      let url = `${
        location.origin
      }/refund/${cryptedHolderRef}/${getRetailerId()}/${getOfferId()}`;
      let data = { email: email, url: url };
      sendMailLaterRefund(data);
      selectPage(refundPages.LATER);
    }
  }

  function refundChoiceContent() {
    return (
      <>
        <div className="subtitle">{t("refund.choices.description")}</div>
        <div className="refund-options">
          {enableIban() && (
            <RadioButton
              checked={bank}
              checkAction={() => chooseRefund(refundPages.BANK)}
              title={t("refund.choices.bank")}
            />
          )}
          {enablePaypal() && (
            <RadioButton
              checked={paypal}
              checkAction={() => chooseRefund(refundPages.PAYPAL)}
              title={t("refund.choices.paypal")}
            />
          )}
          {!!laterChoice && enableLater() && (
            <RadioButton
              checked={later}
              checkAction={() => chooseRefund(refundPages.LATER)}
              title={t("refund.choices.later")}
            />
          )}
        </div>
        <div className="button-area">
          <Button
            text={t("general.next")}
            disabled={!later && !bank && !paypal}
            doAction={() => navigateToChoice()}
          />
        </div>
      </>
    );
  }

  return (
    <SlotMachine
      content={refundChoiceContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default RefundChoices;
