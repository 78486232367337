import React, { useState, useEffect } from "react";
import "./assets/style.scss";
import { ReactComponent as DeleteSVG } from "../../../resources/img/delete.svg";
import { ReactComponent as UploadSVG } from "../../../resources/img/upload.svg";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SlotMachine from "../../../components/Slotmachine/SlotMachine";
import Button from "../../../components/Button/Button";
import { navigate } from "@reach/router";
import {
  cryptedAuthentication,
  sendReceipt,
} from "../../../../utils/catalinaRequests";
import ProgressBar from "@ramonak/react-progress-bar";
import { useStopwatch } from "react-timer-hook";
import config from "../../../../shop/config/config.json";
import { gameRedirect } from "../../../../utils/utils";
import { Loader } from "./Loader/Loader";
import ErrorMessage from "../../../components/Form/Error/ErrorMessage";
import {
  collectInfoBefore,
  enableEmail,
  enableName,
} from "../../../../utils/clientConfig";
import InputText from "../../../components/Form/Input/InputText";
import { onEnter } from "../../../../utils/utils";

function Receipt({ backgroundLayerStyle }) {
  const [t] = useTranslation("message");
  const hiddenFileInput = React.useRef(null);
  const [enable, setEnable] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [base64File, setBase64File] = useState();
  const [receiptId, setReceiptId] = useState();
  const [loading, setLoading] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState();
  const { start, seconds } = useStopwatch({ autoStart: false });

  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (base64File) {
      setEnable(true);
    }
    if (uploadedFileName) {
      setUploadedFileName(uploadedFileName);
    }
  }, [base64File, uploadedFileName]);

  useEffect(() => {
    if (seconds <= config.timerPostReceipt) {
      let progress = Number(
        ((100 / config.timerPostReceipt) * seconds).toFixed()
      );
      setProgressBarValue(progress);
    }
  }, [seconds]);

  function onSubmit(data) {
    if (enable) {
      setEnable(false);
      setErrorMessage(undefined);
      setLoading(true);
      let email;
      if (enableEmail() && collectInfoBefore()) {
        email = data.email;
      } else {
        email = `${Date.now()}@gmail.com`;
      }

      cryptedAuthentication(email).then(() => {
        start();
        sendReceipt(base64File, receiptId)
          .then(async (resp) => {
            setLoading(false);
            setProgressBarValue(0);
            if (resp === "validate") {
              setLoading(false);
              navigate(gameRedirect(config.gameType));
            } else {
              if (resp.receipt_id) {
                setLoading(false);
                setUploadedFileName(undefined);
                setBase64File(undefined);
                setReceiptId(resp.receipt_id);
              }
              let errorText;
              if (resp.mode === "POST") {
                errorText = t("instore.receipt.reupload");
              } else {
                errorText = t("instore.receipt.reupload");
              }
              setErrorMessage(errorText);
            }
          })
          .catch((err) => {
            setLoading(false);
            setProgressBarValue(0);
            setUploadedFileName(undefined);
            setBase64File(undefined);
            setErrorMessage(t("instore.receipt.reupload"));
            console.error("Error", err);
          });
      });
    }
  }
  //*** File gestion */
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async function handleFileRead(event) {
    const file = event.target.files[0];
    setUploadedFileName(file.name);
    const base64 = await convertBase64(file);
    let base64Trim = base64.split(",")[1];
    setBase64File(base64Trim);
  }

  function emptyUploadedFile() {
    setUploadedFileName(undefined);
    setBase64File(undefined);
    setEnable(false);
  }

  function onUploadFile() {
    hiddenFileInput.current.click();
  }

  function Receipt() {
    return (
      <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
        <div className="subtitle">{t("instore.receipt.desc")}</div>

        {!loading ? (
          <>
            <div className="inputFile">
              {!uploadedFileName && (
                <div onClick={onUploadFile} className="uploadFile">
                  <div className="uploadImg">
                    <UploadSVG />
                  </div>
                  <div className="uploadLabel">Upload your receipt</div>
                </div>
              )}
              {uploadedFileName && (
                <div className="uploadedFileText">
                  {uploadedFileName}
                  <div className="deleteImg" onClick={emptyUploadedFile}>
                    <DeleteSVG />
                  </div>
                </div>
              )}
            </div>

            <input
              id="receipt"
              style={{ display: "none" }}
              key={uploadedFileName}
              ref={hiddenFileInput}
              name="receipt"
              type="file"
              placeholder={"receipt"}
              autoComplete="off"
              encType="multipart/form-data"
              onChange={handleFileRead}
            />

            <label>{t("instore.receipt.infoReceipt")}</label>

            {enableName() && collectInfoBefore() ? (
              <>
                <InputText
                  name="firstName"
                  type="text"
                  placeholder={t("landing.name")}
                  autoComplete="off"
                  required
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
                <InputText
                  name="lastName"
                  type="text"
                  placeholder={t("landing.lastName")}
                  autoComplete="off"
                  required
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
              </>
            ) : null}

            {enableEmail() && collectInfoBefore() ? (
              <>
                <InputText
                  name="email"
                  type="text"
                  placeholder={t("landing.email")}
                  autoComplete="off"
                  pattern={{
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "format incorrect",
                  }}
                  required
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
                <label>{t("landing.contactInfoDesc")}</label>
              </>
            ) : null}

            <div className="button-area">
              <Button
                text={t("general.validate")}
                disabled={!isValid || !isDirty || !enable}
                type="submit"
              />
            </div>
          </>
        ) : (
          <>
            <Loader />
            <ProgressBar
              bgColor="#023587"
              completed={progressBarValue}
              className="progress-bar"
            />
          </>
        )}

        {errorMessage && (
          <ErrorMessage message={t("instore.receipt.reupload")} />
        )}
      </form>
    );
  }

  return (
    <SlotMachine
      content={Receipt()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default Receipt;
