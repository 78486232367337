import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import message_en from "./game/resources/i18n/en/message.json";
import message_it from "./game/resources/i18n/it/message.json";
import message_fr from "./game/resources/i18n/fr/message.json";
import message_de from "./game/resources/i18n/de/message.json";
import config from "./shop/config/config.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: config.localeSelected,
    lng: config.localeSelected,
    debug: true,
    resources: {
      en: {
        message: message_en,
      },
      it: {
        message: message_it,
      },
      fr: {
        message: message_fr,
      },
      de: {
        message: message_de,
      },
    },
  });

export default i18n;
