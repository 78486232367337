import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import config from "./shop/config/config.json";
import { Router } from "@reach/router";
import NotFound from "./game/pages/NotAllowed/NotFound";
import Landing from "./game/pages/Landing/Landing";
import CanNotPlay from "./game/pages/NotAllowed/CanNotPlay";
import Refund from "./game/pages/Refund/Refund";
import Success from "./game/pages/Success/Success";
import Legal from "./game/pages/Legals/component/Legal";
import InStore from "./game/pages/InStore/InStore";
import Logo from "./game/components/Logo/Logo";
import {
  cryptedAuthentication,
  getClearingOfficeRef,
  getMemberId,
  getOffer,
  getOfferId,
  receiptInStoreMode,
} from "./utils/catalinaRequests";
import { refundPages } from "./game/pages/Refund/RefundPagesEnum";
import Footer from "./game/components/Footer/Footer";
import Loading from "./game/components/Loading/Loading";
import {
  getAndApplyApiConfiguration,
  getDesktopBackgroundLayer,
} from "./utils/appApiConfiguration";
import { urlReader } from "./utils/appApiConfiguration";

import CasinoGame from "./game/pages/Game/CasinoGame";
import ScratchGame from "./game/pages/Game/ScratchGame";
import WheelGame from "./game/pages/Game/WheelGame";
import FlipGame from "./game/pages/Game/FlipGame";
import { ECommerceMode } from "./utils/clientConfig";
import Teasing from "./game/pages/Teasing/Teasing";
import CollectInfo from "./game/pages/CollectInfo/CollectInfo";

function App() {
  const appEnv = process.env.REACT_APP_ENV;
  let isMobile = useMediaQuery({
    query: "(max-width: " + config.queryMobile + ")",
  });
  const deviceType = isMobile ? "mobile" : "desktop";

  const [infoToken, setInfoToken] = useState("");
  const [allowed, setAllowed] = useState(false);
  const [backgroundLayerStyle, setBackgroundLayerStyle] = useState(undefined);
  const [desktopBackgroundImgLayer, setDesktopBackgroundImgLayer] =
    useState("");
  const [offerContenu, setOfferContenu] = useState({
    backgroundImage: undefined,
  });
  const [loaded, setLoaded] = useState(false);
  const [isLegal, setIsLegal] = useState(false);
  let url = window.location.href;
  let isNotRefund = !url.includes("refund");

  let isInit = JSON.parse(sessionStorage.getItem("init"));
  //** Init Google analytic **/
  useEffect(() => {
    if (!isInit) {
      Init();
    }
    applyApiConfiguration();
    if (receiptInStoreMode()) {
      config.isModeInStore = true;
    }

    setAllowed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Init() {
    initializeGoogleAnalytics();
    if (isNotRefund) {
      urlReader(appEnv, setInfoToken);
    }
    if (ECommerceMode()) {
      sessionStorage.removeItem("offers-" + getClearingOfficeRef());
    }
    sessionStorage.setItem("init", "true");
  }

  function initializeGoogleAnalytics() {
    TagManager.initialize(config.googleTagManagerId);
  }

  //**  Check screen size */
  useEffect(() => {
    let mobileBackgroundLayer = sessionStorage.getItem("bgMobileImageUrl");
    if (isMobile && mobileBackgroundLayer) {
      setBackgroundLayerStyle({
        background: `top / cover no-repeat url(${mobileBackgroundLayer})`,
      });
    } else if (desktopBackgroundImgLayer) {
      setBackgroundLayerStyle({
        background: `top / cover no-repeat url(${desktopBackgroundImgLayer})`,
      });
    }
  }, [isMobile, desktopBackgroundImgLayer]);

  async function applyApiConfiguration() {
    if (ECommerceMode()) {
      if (!getMemberId()) {
        await cryptedAuthentication();
      }
    }

    getOffer(getOfferId())
      .then((offer) => {
        if (offer) {
          setOfferContenu(getAndApplyApiConfiguration(offer));
          getDesktopBackgroundLayer().then((res) =>
            setDesktopBackgroundImgLayer(res)
          );
          setLoaded(true);
        }
      })
      .catch((err) => console.error(err));
  }

  return allowed ? (
    <>
      <body
        className={`${config.gameType} ${deviceType}`}
        style={backgroundLayerStyle}
      >
        <Logo displayLogo isLegal={isLegal} />
        <Router>
          <NotFound default />
          <CanNotPlay path="/can-not-play" />
          <Legal
            path="/legal/:legalType"
            token={infoToken}
            setIsLegal={setIsLegal}
            isMobile={isMobile}
          />
          <InStore
            path="/in-store"
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <Success
            path="/success-email"
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <CasinoGame
            path="/casinogame"
            backgroundLayerStyle={backgroundLayerStyle}
            loaded={loaded}
          />
          <ScratchGame
            path="/scratchgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offerContenu.brandLogo}
            loaded={loaded}
          />
          <WheelGame
            path="/wheelgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offerContenu.brandLogo}
            loaded={loaded}
          />
          <FlipGame
            path="/flipgame"
            backgroundLayerStyle={backgroundLayerStyle}
            brandLogo={offerContenu.brandLogo}
            loaded={loaded}
          />
          <Refund
            path="/refund-choices"
            page={refundPages.CHOICES}
            backgroundLayerStyle={backgroundLayerStyle}
          />

          <Refund
            path="/refund/:email/:retailerId/:offer"
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <Refund path="/refund" backgroundLayerStyle={backgroundLayerStyle} />
          <Teasing path="/teasing/:offer/:retailerId" />
          <CollectInfo
            path="/collect-info"
            loaded={loaded}
            backgroundLayerStyle={backgroundLayerStyle}
          />
          <Landing
            path="/"
            loaded={loaded}
            backgroundLayerStyle={backgroundLayerStyle}
            offerContenu={offerContenu}
          />
        </Router>
        <footer>
          <Footer />
        </footer>
      </body>
    </>
  ) : (
    <Loading />
  );
}

export default App;
