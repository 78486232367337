import React, { useEffect, useState } from "react";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import config from "./../../../shop/config/config.json";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import shopConfig from "../../../shop/config/config.json";
import "./assets/styles.scss";
import CardFaq from "./components/CardFaq";
import { useLocation, useParams } from "@reach/router";
import { getOffer } from "../../../utils/catalinaRequests";
import {
  getAndApplyApiConfiguration,
  getDesktopBackgroundLayer,
} from "../../../utils/appApiConfiguration";
import Loading from "../../components/Loading/Loading";
import Deco2 from "../../resources/img/deco2.png";
import { faq } from "../../../shop/config/faq";
import ShareComponent from "../../components/Share/ShareComponent";
import { useMediaQuery } from "react-responsive";

function Teasing() {
  const { t } = useTranslation("message");
  let params = useParams();
  const [offer, setOffer] = useState();
  const [desktopBackgroundImgLayerLocal, setDesktopBackgroundImgLayer] =
    useState("");
  const isMobile = useMediaQuery({
    query: "(max-width: " + config.queryMobile + ")",
  });

  const location = useLocation();

  let video = offer?.actions?.find(
    (item) => item.action_type === "display_video"
  );

  let shouldDisplay =
    shopConfig?.isModeInStore &&
    new Date().getTime() >= new Date(offer?.display_date).getTime();

  useEffect(() => {
    //block previous click
    window.history.pushState({}, "");
  });

  let offerId = params.offer;
  let retailerId = params.retailerId;
  useEffect(() => {
    if (offerId) {
      getOffer(offerId).then((offer) => {
        setOffer(offer);
        getAndApplyApiConfiguration(offer);
        getDesktopBackgroundLayer().then((resp) =>
          setDesktopBackgroundImgLayer(resp)
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function TeasingContent() {
    return offer ? (
      <div className="game">
        <div className="info-part">
          <div className="txt-date">
            {" "}
            {t("landing.gamePeriod", {
              start: offer?.started_at,
              end: offer?.ended_at,
            })}
          </div>
          <div className="title">{offer?.title}</div>
          <div className="description">{offer?.overview}</div>
        </div>

        <div className="button-area">
          {shouldDisplay ? (
            <Button
              text={"Accéder au jeu"}
              className="button-teasing black-button"
              doAction={() => {
                sessionStorage.setItem("init", "false");
                return document.location.replace(
                  `${location.origin}?offerId=${offerId}&retailerId=${retailerId}&listOffers=${offerId}`
                );
              }}
            />
          ) : null}
          {offer?.detail_url ? (
            <Button
              className="button-teasing"
              text={"Voir la boutique"}
              doAction={() => (document.location = offer?.detail_url)}
            />
          ) : null}
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  return (
    <div className="layout-teasing">
      <SlotMachine
        content={TeasingContent()}
        backgroundLayerStyle={
          !isMobile
            ? {
                background: `center / contain no-repeat url(${desktopBackgroundImgLayerLocal})`,
                zIndex: 10,
              }
            : null
        }
      />
      <div
        className="layout-content"
        style={
          !isMobile
            ? {
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }
            : null
        }
      >
        <div className="container-rule-button">
          <Button
            text={"Voir le règlement du jeu"}
            className="rule-button button-teasing black-button"
            doAction={() => (document.location = offer.external_url)}
          />
        </div>
        {!!video ? (
          <div className="layout-video">
            <h2 className="title-video">{video.args.title}</h2>
            <iframe
              title="promo_video"
              className="video"
              width={350}
              height={480}
              src={`https://www.youtube.com/embed/${video.args.url}`}
            />
          </div>
        ) : null}
        <div className="layout-faq">
          <img src={Deco2} alt="decoration" width={240} className="deco2" />
          <h2 className="title-faq">FAQs</h2>
          <div className="faq-column">
            <div className="left-column">
              {faq.map((each, i) => {
                return i % 2 === 0 ? (
                  <CardFaq
                    question={each.question}
                    answer={each.answer}
                    key={i}
                  />
                ) : null;
              })}
            </div>
            <div className="right-column">
              {faq.map((each, i) => {
                return i % 2 !== 0 ? (
                  <CardFaq
                    question={each.question}
                    answer={each.answer}
                    key={i}
                  />
                ) : null;
              })}
            </div>
          </div>
        </div>
        <div className="share-layout">
          <ShareComponent type="lose" />
        </div>
      </div>
    </div>
  );
}

export default Teasing;
