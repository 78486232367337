import styled from "styled-components";
import { NonDraggableImage } from "./styledComponents";

export const WheelCanvasStyle = styled.canvas`
min-width : 300px
min-height : 300px
width: ${(props) => props.sizeWheel}px;
height: ${(props) => props.sizeWheel}px;
`;

export const HandlePart = styled.div`
  position: absolute;
  background: url(${(props) => props.image}) no-repeat;
  background-position: center center;
  background-size: contain;
  &:hover {
    cursor: pointer;
  }
  z-index: 6;
`;

export const RouletteContainer = styled.div`
  position: relative;
  width: 70vw;
  max-width: ${(props) => props.sizeWheel}px;
  height: ${(props) => props.sizeWheel}px;
  max-height: 340px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
`;

export const RotationContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(${(props) => props.startRotationDegrees}deg);
  &.started-spinning {
    animation: spin ${({ startSpinningTime }) => startSpinningTime / 1000}s
        // cubic-bezier(0.71, -0.29, 0.96, 0.9) 0s 1 normal forwards running,
      // cubic-bezier(0.71, 0.29, 0.96, 0.9) 0s 1 normal forwards running,
      cubic-bezier(0.71, 0.29, 0.96, 0.9) 0s 1 normal forwards running,
      continueSpin ${({ continueSpinningTime }) => continueSpinningTime / 1000}s
        linear ${({ startSpinningTime }) => startSpinningTime / 1000}s 1 normal
        forwards running,
      stopSpin ${({ stopSpinningTime }) => stopSpinningTime / 1000}s
        cubic-bezier(0, 0, 0.35, 1.02)
        ${({ startSpinningTime, continueSpinningTime }) =>
          (startSpinningTime + continueSpinningTime) / 1000}s
        1 normal forwards running;
  }
  @keyframes spin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin {
    from {
      transform: rotate(${(props) => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${(props) => 1440 + props.finalRotationDegrees}deg);
    }
  }
`;

export const RouletteSelectorImage = styled(NonDraggableImage)`
  position: absolute;
  z-index: 5;
  right: 37px;
  top: 18px;
`;
