import {
  getLottery,
  getMemberId,
  getOfferId,
  getRetailerId,
  postLottery,
  postTriggerableOffers,
} from "../../../../utils/catalinaRequests";
import {
  ECommerceMode,
  PinMode,
  ReceiptMode,
} from "../../../../utils/clientConfig";

export async function getGameResult(setWinner, setAllowed, setRewardDesc) {
  //if e-commerce
  if (ECommerceMode()) {
    await postTriggerableOffers().catch((err) => {
      console.error(err);
      window.location.href = "/can-not-play";
    });
  } else if (ReceiptMode() || PinMode()) {
    //if print ou receipt
    await postLottery(getMemberId(), getRetailerId(), getOfferId()).catch(
      (err) => {
        console.error("Error on Post Lottery", err);
        window.location.href = "/can-not-play";
      }
    );
  }
  //for pin, print & ecommerce
  await getLottery(getMemberId(), getRetailerId(), getOfferId()).then(
    (resp) => {
      setAllowed(true);
      sessionStorage.setItem("rewardId", resp.reward_id);
      if (resp.result === "won") {
        setWinner(true);
      } else {
        setWinner(false);
      }
      if (resp?.prize?.description) {
        setRewardDesc(resp?.prize?.description);
      }
    }
  );
}
