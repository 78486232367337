export const wheelItems = [
  {
    text: "lose",
  },
  {
    img: require("../assets/img/clover.png"),
  },
  {
    text: "lose",
  },
  {
    img: require("../assets/img/clover.png"),
  },
  {
    text: "lose",
  },
  {
    img: require("../assets/img/clover.png"),
  },
  {
    text: "lose",
  },
  {
    img: require("../assets/img/clover.png"),
  },
];
