import Moment from "moment";
import appConfig from "../shop/config/config.json";
import CryptoJS from "crypto-js";
import icoWin from "../shop/assets/img/icoWin.png";
import desktopBackgroundLayer from "../shop/assets/img/background-layer.png";
import bottomBackground from "../shop/assets/img/bottomBackground.png";
import config from "../shop/config/config.json";

export function getAndApplyApiConfiguration(offer) {
  const body = document.body;
  const container = document.body;
  const footer = document.getElementsByClassName("footer")[0];
  if (config.fixedBottomBackground) {
    body.style.background = `url(${bottomBackground})`;
    body.style.backgroundRepeat = `no-repeat`;
    body.style.backgroundSize = "100% 100%";
    // Container and footer transparent then we see the texture
    // in the whole page
    container.style.backgroundColor = "transparent";
    footer.style.backgroundColor = "transparent";
  } else {
    footer.style.backgroundColor = offer.background_color;
  }

  const startDate = getDate(offer.started_at);
  const endDate = getDate(offer.ended_at);
  const displayDate = offer.display_date && getDate(offer.display_date);
  const offerTitle = offer.title;
  const offerFullDescription = offer.overview || offer.subtitle;

  // 0 Head packshot for mobile
  // 1.Footer mobile
  // 2.Banniere_Homepage _mobile => Not used for the moment
  // 3.Banniere Pages_mobile => Not used for the moment
  // 4.Bannières Thank you page_mobile (slider multi offre)
  // 5.Banniere_Homepage _desktop => Not used for the moment
  // 6.Banniere Pages_desktop => Not used for the moment
  // 7.Bannières thank you page_desktop (slider multi offre)

  sessionStorage.setItem("bgDesktopImageUrl", offer.picture_url);
  sessionStorage.setItem("bgMobileImageUrl", offer.carousel_pictures?.[0]);
  sessionStorage.setItem("footer", offer.carousel_pictures?.[1]);
  sessionStorage.setItem("CGU", offer.external_url);
  sessionStorage.setItem("winIcon", offer.brand_logo_url);
  sessionStorage.setItem("offerBackgroundColor", offer.background_color);
  sessionStorage.setItem("detailURL", offer.detail_url);
  // sessionStorage.setItem(
  //   "scratchImg",
  //   offer.carousel_pictures[offer?.carousel_pictures?.length - 1]
  // );
  sessionStorage.setItem("offerTitle", offerTitle);

  return {
    offerTitle: offerTitle,
    CGU: offer.external_url,
    offerDescription: offerFullDescription,
    startDate: startDate,
    displayDate: displayDate,
    endDate: endDate,
    backgroundColor: offer.background_color,
    brandLogo: offer.brand_logo_url,
  };
}

export async function getOfferBackgroundColor() {
  return sessionStorage.getItem("offerBackgroundColor");
}
export async function getDesktopBackgroundLayer() {
  return getImageIfExistOrReturnDefault(
    sessionStorage.getItem("bgDesktopImageUrl"),
    desktopBackgroundLayer
  );
}

export async function getWinIcon() {
  return getImageIfExistOrReturnDefault(
    sessionStorage.getItem("winIcon"),
    icoWin
  );
}

export function getClientClass() {
  const partnerName = process.env.REACT_APP_PARTNER_NAME;
  return partnerName.toLowerCase();
}

export async function getFooterImage() {
  return getImageIfExistOrReturnDefault(sessionStorage.getItem("footer"), null);
}

async function getImageIfExistOrReturnDefault(imgUrl, defaultUrl) {
  if (imgUrl !== "undefined" && imgUrl !== "null") {
    return await fetch(imgUrl, { method: "HEAD", mode: "no-cors" })
      .then(() => {
        return imgUrl;
      })
      .catch((err) => {
        console.error("Error:", err);
        return defaultUrl;
      });
  } else return Promise.resolve(defaultUrl);
}

export function getDate(date) {
  return Moment(date).format(appConfig.dateFormat);
}

export async function extractGameInformationFromToken(token) {
  const decrypted = decryptionWithCryptoJS(token);
  const payload = JSON.parse(decrypted);
  console.log("decrypted Url Info :", payload);
  sessionStorage.setItem("retailerId", payload.retailerId);
  sessionStorage.setItem("retailerRef", payload.retailerRef);
  sessionStorage.setItem("holderRef", payload.holderRef);
  sessionStorage.setItem("offerId", payload.offerId);
  sessionStorage.setItem("clearingOfficeRef", payload.clearingOfficeRef);
  sessionStorage.setItem("receipt", payload.receipt);
  sessionStorage.setItem("totalAmount", payload.totalAmount);
  sessionStorage.setItem("products", JSON.stringify(payload.products));
  sessionStorage.setItem("action_ids", JSON.stringify(payload.action_ids));
  sessionStorage.setItem("listOffers", JSON.stringify(payload.listOffers));
  if (sessionStorage.getItem("offers-" + payload.clearingOfficeRef)) {
    sessionStorage.setItem(
      "offers-" + payload.clearingOfficeRef,
      JSON.stringify(
        payload.listOffers.map((o) => ({
          id: o.id,
          played: false,
        }))
      )
    );
  } else {
    const gamesPlayedList = JSON.parse(
      sessionStorage.getItem("offers-" + payload.clearingOfficeRef)
    );
    let isCurrentOfferAlreadyPlayed = gamesPlayedList?.filter(
      (g) => g.id === payload.offerId && g.played
    ).length;
    if (isCurrentOfferAlreadyPlayed) {
      const nextOffer = gamesPlayedList?.filter((g) => !g.played)[0];
      sessionStorage.setItem("offerId", nextOffer.id);
    }
  }
}

export function decryptionWithCryptoJS(cipher) {
  const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_UTF8_TOKEN);
  const plainText = CryptoJS.AES.decrypt(cipher, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  return plainText.toString(CryptoJS.enc.Utf8);
}

export function extractOffer(offers, offerId) {
  let availableOffer = null;
  offers.forEach((offer) => {
    if (offer.id.toString() === offerId) {
      availableOffer = offer;
    }
  });
  return availableOffer;
}

export function urlReader(appEnv, setInfoToken) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("info");
  setInfoToken(token);
  if (token) {
    extractGameInformationFromToken(token);
  } else {
    if (appEnv !== "prod") {
      console.log("This is an env staging ou dev");
      if (urlParams.get("holderRef")) {
        sessionStorage.setItem("holderRef", urlParams.get("holderRef"));
      }
      if (urlParams.get("offerId")) {
        sessionStorage.setItem("offerId", urlParams.get("offerId"));
      }
      if (urlParams.get("retailerId")) {
        sessionStorage.setItem("retailerId", urlParams.get("retailerId"));
      }
      if (urlParams.get("retailerRef")) {
        sessionStorage.setItem("retailerRef", urlParams.get("retailerRef"));
      }
      if (urlParams.get("clearingOfficeRef")) {
        sessionStorage.setItem(
          "clearingOfficeRef",
          urlParams.get("clearingOfficeRef")
        );
      }
      if (urlParams.get("receipt")) {
        sessionStorage.setItem("receipt", urlParams.get("receipt"));
      }
      if (urlParams.get("totalAmount")) {
        sessionStorage.setItem("totalAmount", urlParams.get("totalAmount"));
      }

      if (urlParams.has("listOffers")) {
        let listOfferArray = urlParams.getAll("listOffers");
        let offerArray = listOfferArray.map((item, index) => {
          return { id: item, played: false };
        });

        sessionStorage.setItem(
          "offers-" + sessionStorage.getItem("clearingOfficeRef"),
          JSON.stringify(offerArray)
        );
      }
      if (
        urlParams.has("productsCode[]") &&
        urlParams.has("productsQuantity[]") &&
        urlParams.has("productsAmount[]")
      ) {
        let productsCode = urlParams.getAll("productsCode[]");
        let productQuantity = urlParams.getAll("productsQuantity[]");
        let productAmount = urlParams.getAll("productsAmount[]");
        let products = productsCode.map((item, index) => ({
          code: item,
          amount: productAmount[index],
          quantity: productQuantity[index],
        }));
        sessionStorage.setItem("products", JSON.stringify(products));
      }
      if (urlParams.has("action_ids")) {
        let action_ids = urlParams.getAll("action_ids");
        sessionStorage.setItem("action_ids", JSON.stringify(action_ids));
      }
    }
    const gamesPlayedList = JSON.parse(
      sessionStorage.getItem(
        "offers-" + sessionStorage.getItem("clearingOfficeRef")
      )
    );
    let isCurrentOfferAlreadyPlayed = gamesPlayedList?.filter(
      (g) => g.id === +sessionStorage.getItem("offerId") && g.played
    ).length;
    if (isCurrentOfferAlreadyPlayed) {
      const nextOffer = gamesPlayedList?.filter((g) => !g.played)[0];
      if (nextOffer) {
        sessionStorage.setItem("offerId", nextOffer.id);
      }
    }
  }
}
