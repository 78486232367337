import React, { useState, useEffect } from "react";
import RefundChoices from "./RefundChoices";
import RefundPaypal from "./RefundPayPal";
import { refundPages } from "./RefundPagesEnum";
import RefundLater from "./RefundLater";
import RefundBank from "./RefundBank";
import { useParams } from "@reach/router";
import {
  cryptedAuthenticationRefundLater,
  getOffer,
} from "../../../utils/catalinaRequests";
import {
  getAndApplyApiConfiguration,
  getDesktopBackgroundLayer,
} from "../../../utils/appApiConfiguration";

function Refund({ page, backgroundLayerStyle }) {
  const params = useParams();
  const [chosenPage, setChosenPage] = useState(page);
  const [laterChoice, setLaterChoice] = useState(true);
  const [desktopBackgroundImgLayerLocal, setDesktopBackgroundImgLayer] =
    useState("");

  function selectPage(choice) {
    setChosenPage(choice);
  }

  useEffect(() => {
    let email = params.email;
    let retailerId = params.retailerId;
    let offerId = params.offer;
    if (!!email && !!retailerId) {
      cryptedAuthenticationRefundLater(email, retailerId, offerId);
      setChosenPage(refundPages.CHOICES);
      setLaterChoice(false);
    }
    if (offerId) {
      getOffer(offerId).then((res) => {
        getAndApplyApiConfiguration(res);
        getDesktopBackgroundLayer().then((resp) =>
          setDesktopBackgroundImgLayer(resp)
        );
      });
    }
  }, []);

  let laterBackground = {
    backgroundImage: `url(${desktopBackgroundImgLayerLocal})`,
  };

  function chosenRefundPage() {
    switch (chosenPage) {
      case refundPages.CHOICES:
        return (
          <RefundChoices
            selectPage={selectPage}
            laterChoice={laterChoice}
            backgroundLayerStyle={backgroundLayerStyle || laterBackground}
          />
        );

      case refundPages.PAYPAL:
        return (
          <RefundPaypal
            selectPage={selectPage}
            backgroundLayerStyle={backgroundLayerStyle || laterBackground}
          />
        );
      case refundPages.BANK:
        return (
          <RefundBank
            selectPage={selectPage}
            backgroundLayerStyle={backgroundLayerStyle || laterBackground}
          />
        );
      case refundPages.LATER:
        return (
          <RefundLater
            backgroundLayerStyle={backgroundLayerStyle || laterBackground}
          />
        );
      default:
        return (
          <RefundChoices
            selectPage={selectPage}
            backgroundLayerStyle={backgroundLayerStyle || laterBackground}
          />
        );
    }
  }

  return chosenRefundPage();
}

export default Refund;
