import React from "react";
import "./assets/style.scss";
import { Link } from "@reach/router";
import logo from "../../../shop/assets/img/logo.png";

function Logo({ displayLogo, isLegal }) {
  return (
    displayLogo && (
      <div
        className="app-container"
        style={
          isLegal
            ? { backgroundColor: "white" }
            : { backgroundColor: "transparent" }
        }
      >
        <Link to="/" className="logo">
          <img src={logo} alt="" width="100" />
        </Link>
      </div>
    )
  );
}

export default Logo;
