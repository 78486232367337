import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import { useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import leverSound from "../../resources/assets/sound/lever.mp3";
import winSound from "../../resources/assets/sound/Win.mp3";
import Screen from "../Game/components/Casino/Screen";
import Button from "../../components/Button/Button";
import {
  getHolderRef,
  getOfferId,
  getOfferOdds,
  getRetailerId,
  receiptInStoreMode,
} from "../../../utils/catalinaRequests";
import Win from "./Win/Win";
import Lost from "./Lost/Lost";
import Loading from "../../components/Loading/Loading";
import "./assets/styles.scss";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import { getGameResult } from "./utils/gameUtils";
import {
  areAllPlayed,
  setResultGameOffer,
  setResultGameOfferEcomm,
} from "../../../utils/utils";
import { ECommerceMode } from "../../../utils/clientConfig";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import { useMediaQuery } from "react-responsive";
import config from "./../../../shop/config/config.json";
let cloverImg = require("./../../../shop/assets/img/clover.png");

const REDIRECTING_TIME = 3000;

function CasinoGame({ backgroundLayerStyle, loaded }) {
  const [t] = useTranslation("message");
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  const [animated, setAnimated] = useState(false);
  let [winner, setWinner] = useState();
  let [gameEnded, setGameEnded] = useState(false);
  let [isScreenFinished, setScreenFinished] = useState(false);
  const [leverPulled] = useSound(leverSound, { volume: 0.05 });
  const [winSoundPlay] = useSound(winSound, { volume: 0.3 });
  let [odds, setOdds] = useState(0);
  let [oneShoot, setOneShoot] = useState(false);
  let [rewardDesc, setRewardDesc] = useState("");
  let oddsLocal = getOfferOdds();

  const isMobile = useMediaQuery({
    query: "(max-width: " + config.queryMobile + ")",
  });
  useEffect(() => {
    //block previous click
    window.history.pushState({}, "");
  });

  useEffect(() => {
    if (areAllPlayed()) {
      navigate("/can-not-play");
    } else {
      getGameResult(setWinner, setAllowed, setRewardDesc);
    }
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: `${window.location.pathname}`,
        title: "game_display",
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: "e-commerce",
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOdds(oddsLocal);
  }, [oddsLocal]);

  let prevOdds = usePrevious(odds);
  useEffect(() => {
    if (odds === "one" || prevOdds === "one") {
      setOneShoot(true);
    } else if (oneShoot) {
      setOneShoot(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [odds]);

  useEffect(() => {
    if (isScreenFinished) {
      setTimeout(() => {
        if (winner && (odds === 0 || !odds)) {
          winSoundPlay();
        }
        setGameEnded(true);
      }, REDIRECTING_TIME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenFinished]);

  function play() {
    if (!animated) {
      if (getHolderRef() || receiptInStoreMode()) leverPulled();
      setAnimated(true);
      if (!ECommerceMode()) {
        setResultGameOffer();
      }
    }
  }

  function newGame() {
    setAnimated(false);
    setGameEnded(false);
    setScreenFinished(false);
  }

  console.log(odds, ECommerceMode());

  function gameContent() {
    return allowed && loaded ? (
      <div className="game-content">
        <div className="game">
          {ECommerceMode() && !oneShoot && (
            <div className="subtitle odds-text">
              <img
                src={cloverImg}
                width={30}
                className="clover-game"
                alt="clover"
              />

              {t("game.oddsTitle", { nbChance: odds })}
            </div>
          )}
          {isMobile ? (
            <div className="subtitle">{t("game.titleMobile")}</div>
          ) : (
            <div className="subtitle desktop-only">{t("game.title")}</div>
          )}
          <div className="screen-container" onClick={play}>
            <Screen
              animate={animated}
              speed={0.8}
              screenNumber={1}
              winner={winner && (!odds || odds === "one" || odds === 1)}
            />
            <Screen
              animate={animated}
              speed={0.6}
              screenNumber={2}
              winner={winner && (!odds || odds === "one" || odds === 1)}
            />
            <Screen
              animate={animated}
              speed={0.4}
              screenNumber={3}
              winner={winner && (!odds || odds === "one" || odds === 1)}
              onFinish={() => {
                setScreenFinished(true);
                if (ECommerceMode()) {
                  setResultGameOfferEcomm();
                }
              }}
            />
          </div>
          <div className="button-area">
            <Button text={t("game.play")} disabled={animated} doAction={play} />
          </div>
        </div>
        <div className="lever" onClick={() => play()}>
          <div className={`slot-hand ${animated ? "hand-slide" : ""}`} />
          <div className={`slot-lever ${animated ? "lever-slide" : ""}`} />
          <div className="slot-lever-support" />
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  function renderedGame() {
    if (!gameEnded) {
      return (
        <SlotMachine
          content={gameContent()}
          backgroundLayerStyle={backgroundLayerStyle}
          flat
        />
      );
    } else {
      if (winner && (odds === 0 || !odds)) {
        return (
          <Win
            backgroundLayerStyle={backgroundLayerStyle}
            rewardDesc={rewardDesc}
          />
        );
      } else {
        return (
          <Lost
            newGame={newGame}
            odds={odds}
            backgroundLayerStyle={backgroundLayerStyle}
          />
        );
      }
    }
  }

  return renderedGame();
}

export default CasinoGame;
