import { hexToRgb } from "../../../utils/utils";
import "./assets/style.scss";

function SlotMachine({ content, backgroundLayerStyle, flat }) {
  let offerColor = sessionStorage.getItem("offerBackgroundColor");
  let offerRgb = hexToRgb(offerColor);
  let color = offerRgb
    ? `rgba(${offerRgb.r}, ${offerRgb.g}, ${offerRgb.b}, 0.7)`
    : "transparent";

  return (
    <div className="display-screen">
      <div
        className="game-screen"
        style={{
          backgroundColor: !flat ? color : "transparent",
        }}
      >
        <div className="game-screen-content">{content}</div>
      </div>
    </div>
  );
}

export default SlotMachine;
