import React from "react";
import Receipt from "./components/Receipt";
import Pin from "./components/Pin";
import { receiptInStoreMode } from "../../../utils/catalinaRequests";

function InStore({ backgroundLayerStyle }) {
  let receiptMode = receiptInStoreMode();
  return receiptMode ? (
    <Receipt backgroundLayerStyle={backgroundLayerStyle} />
  ) : (
    <Pin backgroundLayerStyle={backgroundLayerStyle} />
  );
}

export default InStore;
