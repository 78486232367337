import React, { useRef } from "react";

import "./assets/style.scss";
import union from "../../../../shop/assets/img/union.png";
import { useTranslation } from "react-i18next";
const InputText = ({
  defaultValue,
  type,
  placeholder,
  autoComplete,
  errorMessage,
  displayErrors,
  register,
  required,
  name,
  pattern,
  watch,
  label,
  ...rest
}) => {
  const inputRef = useRef(null);
  let value = watch(name);
  const [t] = useTranslation("message");

  const textlabel = (required) => {
    return required ? `${placeholder}${t("general.needed")}` : placeholder;
  };

  return (
    <div className="text-input">
      <div className="top-placeholder">{value ? textlabel(required) : ""}</div>
      <input
        ref={inputRef}
        {...register(name, { required: required, pattern: pattern })}
        defaultValue={defaultValue}
        type={type}
        autoComplete={autoComplete}
        placeholder={textlabel(required)}
        {...rest}
      />
      {displayErrors && (
        <div className="input-error-message">
          {errorMessage}
          <img src={union} alt="" />
        </div>
      )}
      {label ? <label>{label}</label> : null}
    </div>
  );
};

export default InputText;
