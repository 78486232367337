import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import "./assets/styles.scss";
import { useTranslation } from "react-i18next";
const INITIAL_COUNT = 6;

let cloverImg = require("./../../../shop/assets/img/clover.png");

export default function Chance({ newGame, odds }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [timeRemaining, setTimeRemaning] = useState(INITIAL_COUNT);
  const [t] = useTranslation("message");

  function afterOpenModal() {
    setTimeout(() => {
      setIsOpen(false);
      newGame();
    }, 5000);
  }

  function openModal() {
    setIsOpen(true);
  }
  function goNextGame() {
    newGame();
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useEffect(() => {
    const id = setTimeout(() => {
      openModal();
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  useInterval(() => {
    if (timeRemaining > 0) {
      setTimeRemaning(timeRemaining - 1);
    }
  }, 1000);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      className="modal-chance"
    >
      <h2 className="title-chance">{t("game.modalChance.descTop")}</h2>
      <div className="luck-content">
        <h3 className="subtitle-modal">
          {t("game.modalChance.luck", { nbChance: odds })}
        </h3>
      </div>
      <img src={cloverImg} width={50} className="clover" alt="clover" />
      <h2 className="title-chance">{t("game.modalChance.descBottom")}</h2>

      <div className="button-play-modal" onClick={goNextGame}>
        {t("game.modalChance.play")}
      </div>
      <p>{t("game.modalChance.timer", { sec: timeRemaining })}</p>
    </Modal>
  );
}
