import React, { useEffect } from "react";
import SlotMachine from "../../../components/Slotmachine/SlotMachine";
import Button from "../../../components/Button/Button";
import eye from "../../../../shop/assets/img/eye.png";
import lips from "../../../../shop/assets/img/lips.png";
import ShareComponent from "../../../components/Share/ShareComponent";
import { useTranslation } from "react-i18next";
import { getOfferId, getRetailerId } from "../../../../utils/catalinaRequests";
import Replay from "../../../components/Modal/Replay";
import shopConfig from "../../../../shop/config/config.json";
import "./styles.scss";
import Chance from "../../../components/Modal/Chance";

function Lost({ backgroundLayerStyle, newGame, odds }) {
  const { t } = useTranslation("message");
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "game_played",
        offer: getOfferId(),
        retailer: getRetailerId(),
        game_result: "Lost",
        canal: "e-commerce",
      },
    });
  }, []);

  function redirectToWebsite() {
    window.dataLayer.push({
      event: "retailer_link_lost",
    });
    shopConfig?.LostMessage?.urlCTA
      ? (document.location = shopConfig.LostMessage.urlCTA)
      : (document.location = process.env.REACT_APP_HOME_SHOPPING_URL);
  }

  function LostContent() {
    return (
      <>
        {odds <= 0 || !odds ? (
          <Replay />
        ) : (
          <Chance newGame={newGame} odds={odds} />
        )}

        <div className="content-up">
          <img src={eye} alt="" />{" "}
          <div className="title">
            {t("general.sorry") + "\n" + t("lost.lost")}
          </div>
          <img src={eye} alt="" />
        </div>
        <div className="description">{t("lost.description")}</div>
        {shopConfig?.enableShare && <ShareComponent type="lost" />}
        <div className="button-area">
          <Button
            text={shopConfig?.LostMessage?.txtCTA || t("general.btnGoToSite")}
            doAction={() => redirectToWebsite()}
          />
        </div>
        {!shopConfig?.enableShare && <img className="lips" src={lips} alt="" />}
      </>
    );
  }

  return (
    <SlotMachine
      content={LostContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default Lost;
