import { getClearingOfficeRef, getOfferId } from "./catalinaRequests";

export const onEnter = (e) => {
  if (e.key === "Enter") {
    e.target.blur();
  }
};

export const areAllPlayed = () => {
  let gamesPlayedList = JSON.parse(
    sessionStorage.getItem("offers-" + getClearingOfficeRef())
  );
  if (typeof gamesPlayedList?.[0]?.odds === "number") {
    return (
      gamesPlayedList?.filter((g) => g.odds === 0)?.length ===
      gamesPlayedList?.length
    );
  }

  return (
    !!gamesPlayedList?.filter((g) => g.played === "true")?.length ===
    gamesPlayedList?.length
  );
};

export function setResultGameOffer() {
  let gamesPlayedList = JSON.parse(
    sessionStorage.getItem("offers-" + getClearingOfficeRef())
  );
  let resultGamesOffers = gamesPlayedList;
  resultGamesOffers.map((o) => {
    if (o?.id.toString() === getOfferId()) {
      return (o.played = true);
    } else {
      return o;
    }
  });
  sessionStorage.setItem(
    "offers-" + getClearingOfficeRef(),
    JSON.stringify(resultGamesOffers)
  );
}
export function setResultGameOfferEcomm() {
  let gamesPlayedList = JSON.parse(
    sessionStorage.getItem("offers-" + getClearingOfficeRef())
  );
  let resultGamesOffers = gamesPlayedList;
  resultGamesOffers.map((o) => {
    if (+o?.id === +getOfferId()) {
      if (o.odds !== "one") {
        return (o.odds = o.odds - 1);
      } else {
        return (o.odds = 0);
      }
    } else {
      return o;
    }
  });
  sessionStorage.setItem(
    "offers-" + getClearingOfficeRef(),
    JSON.stringify(resultGamesOffers)
  );
}

export function gameRedirect(type) {
  switch (type) {
    case "scratch":
      return "/scratchgame";
    case "casino":
      return "/casinogame";
    case "wheel":
      return "/wheelgame";
    case "flip":
      return "/flipgame";
    default:
      return "/casinogame";
  }
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}
