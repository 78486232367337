import shopConfig from "../shop/config/config.json";
import { getHolderRef, receiptInStoreMode } from "./catalinaRequests";

export function withRefundFlow() {
  return enableIban() || enablePaypal() || enableLater();
}
export function withDynamicReward() {
  return !!shopConfig.enableDynamicReward;
}

export function enableIban() {
  return !!shopConfig.enableRefundFlow.iban;
}

export function enablePaypal() {
  return !!shopConfig.enableRefundFlow.paypal;
}

export function enableLater() {
  return !!shopConfig.enableRefundFlow.later;
}

export function collectInfoBefore() {
  return shopConfig.getUserInfo.type === "before";
}

export function collectInfoAfter() {
  return shopConfig.getUserInfo.type === "after";
}

export function enableEmail() {
  return shopConfig.getUserInfo.email;
}

export function enableName() {
  return shopConfig.getUserInfo.name;
}
export function enableStreet() {
  return shopConfig?.getUserInfo?.street;
}

export function ECommerceMode() {
  return getHolderRef() && !shopConfig.isModeInStore && !receiptInStoreMode();
}

export function ReceiptMode() {
  return receiptInStoreMode() && shopConfig.isModeInStore && !getHolderRef();
}

export function PinMode() {
  return shopConfig.isModeInStore && !getHolderRef() && !receiptInStoreMode();
}
