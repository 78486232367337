import React, { useEffect } from "react";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import Replay from "../../components/Modal/Replay";

function RefundLater({ backgroundLayerStyle }) {
  const { t } = useTranslation("message");
  useEffect(() => {
    window.history.pushState({}, "");
  });

  function RefundLaterContent() {
    return (
      <>
        <Replay />
        <div className="title">{t("success.later.title")}</div>
        <div className="subtitle">{t("success.later.description")}</div>
        <div className="button-area">
          <Button
            text={t("success.btn")}
            doAction={() =>
              (document.location = process.env.REACT_APP_HOME_SHOPPING_URL)
            }
          />
        </div>
      </>
    );
  }

  return (
    <SlotMachine
      content={RefundLaterContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default RefundLater;
