import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { gameRedirect, onEnter } from "../../../utils/utils";
import Loading from "../../components/Loading/Loading";
import InputText from "../../components/Form/Input/InputText";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import Button from "../../components/Button/Button";
import {
  cryptedAuthentication,
  getMemberId,
  patchMember,
} from "../../../utils/catalinaRequests";
import {
  collectInfoBefore,
  enableEmail,
  enableName,
  enableStreet,
} from "../../../utils/clientConfig";
import { useNavigate } from "@reach/router";
import config from "./../../../shop/config/config.json";
import "./assets/styles.scss";
import { useMediaQuery } from "react-responsive";

export default function CollectInfo({ backgroundLayerStyle, loaded }) {
  const [t] = useTranslation("message");
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  let isMobile = useMediaQuery({
    query: "(max-width: " + config.queryMobile + ")",
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });

  async function onSubmit(data) {
    setLoading(true);
    let email = data.email;
    try {
      await cryptedAuthentication(email);
      if (enableName() || enableStreet()) {
        let body = {};
        if (enableName()) {
          body.lastname = data.lastName;
          body.firstname = data.firstName;
        }

        if (enableName() && isMobile) {
          body.lastname = data.fullName.split(" ")[0];
          body.firstname = data.fullName.split(" ")[1];
        }
        if (enableStreet()) {
          body.street = data.street;
          body.street_bis = data.streetBis;
        }
        patchMember(body, getMemberId());
      }
      navigate(gameRedirect(config.gameType));
    } catch (e) {
      console.error("ERROR :", e);
    }
  }

  function CollectInfoBeforeContent() {
    return loaded ? (
      <div className="collect-info-container">
        <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
          <div className="subtitle">{t("collectInfo.title")}</div>
          {enableName() && collectInfoBefore() ? (
            !isMobile ? (
              <div className="container-line-input">
                <InputText
                  name="firstName"
                  type="text"
                  placeholder={t("landing.firstName")}
                  autoComplete="off"
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
                <InputText
                  name="lastName"
                  type="text"
                  placeholder={t("landing.lastName")}
                  autoComplete="off"
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
              </div>
            ) : (
              <div className="container-line-input">
                <InputText
                  name="fullName"
                  type="text"
                  placeholder={t("landing.fullName")}
                  autoComplete="off"
                  errorMessage={t("refund.intro.error")}
                  register={register}
                  watch={watch}
                />
              </div>
            )
          ) : null}
          {enableStreet() && collectInfoBefore() ? (
            <div className="container-line-input">
              <InputText
                name="street"
                type="text"
                placeholder={t("landing.street")}
                autoComplete="off"
                errorMessage={t("refund.intro.error")}
                register={register}
                watch={watch}
              />
              <InputText
                name="streetBis"
                type="text"
                placeholder={t("landing.streetBis")}
                autoComplete="off"
                errorMessage={t("refund.intro.error")}
                register={register}
                watch={watch}
              />
            </div>
          ) : null}

          {enableEmail() && collectInfoBefore() ? (
            <div className="container-line-input">
              <InputText
                name="email"
                type="text"
                placeholder={t("landing.email")}
                autoComplete="off"
                pattern={{
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "format incorrect",
                }}
                required
                errorMessage={t("refund.intro.error")}
                register={register}
                watch={watch}
                label={t("landing.contactInfoDesc")}
              />
            </div>
          ) : null}

          <div className="button-area">
            <Button
              text={t("general.validate")}
              disabled={!isValid || !isDirty || loading}
              type="submit"
            />
          </div>
        </form>
      </div>
    ) : (
      <Loading />
    );
  }

  return (
    <SlotMachine
      content={CollectInfoBeforeContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}
