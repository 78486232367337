import React from "react";
import "../assets/styles.scss";

const CardFaq = ({ question, answer }) => {
  return (
    <div className="card-faq">
      <div className="quest-faq">{question}</div>
      <div className="answer-faq">{answer}</div>
    </div>
  );
};

export default CardFaq;
