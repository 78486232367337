import React from "react";

import "./assets/style.scss";
import { ReactComponent as CheckSVG } from "./assets/images/checkMark.svg";

function Checkbox({ checkAction, checked, setChecked }) {
  function check() {
    setChecked(!checked);
    if (checkAction) {
      checkAction();
    }
  }

  return (
    <div
      className={`checkbox ${checked ? "checked" : ""}`}
      onClick={() => check()}
    >
      {checked ? <CheckSVG /> : null}
    </div>
  );
}

export default Checkbox;
