import React, { useEffect, useState } from "react";
import {
  getOfferId,
  getOfferOdds,
  getRetailerId,
  getScratchImg,
} from "../../../utils/catalinaRequests";
import Win from "./Win/Win";
import Lost from "./Lost/Lost";
import winSound from "../../resources/assets/sound/Win.mp3";
import { useNavigate } from "@reach/router";
import Loading from "../../components/Loading/Loading";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import winningImage from "./../../../shop/assets/img/ico2.png";
import losingImage from "./../../../shop/assets/img/ico1.png";
import "./assets/styles.scss";
import Button from "../../components/Button/Button";
import { useMediaQuery } from "react-responsive";
import config from "./../../../shop/config/config.json";
import { getGameResult } from "./utils/gameUtils";
import {
  areAllPlayed,
  setResultGameOffer,
  setResultGameOfferEcomm,
} from "../../../utils/utils";
import { ECommerceMode } from "../../../utils/clientConfig";
import { usePrevious } from "../../../utils/hooks/usePrevious";
import ScratchMe from "./components/Scratch";
import scrachSound from "../../resources/assets/sound/scratch.mp3";

let cloverImg = require("./../../../shop/assets/img/clover.png");

const REDIRECTING_TIME = 3000;

function ScratchGame({ backgroundLayerStyle, loaded, brandLogo }) {
  const isMobile = useMediaQuery({
    query: "(max-width: " + config.queryMobile + ")",
  });
  const [t] = useTranslation("message");
  const navigate = useNavigate();
  const [allowed, setAllowed] = useState(false);
  let [winner, setWinner] = useState();
  let [gameEnded, setGameEnded] = useState(false);
  let [isScreenFinished, setScreenFinished] = useState(false);
  let [playAuto, setPlayAuto] = useState(false);
  const [winSoundPlay] = useSound(winSound, { volume: 0.3 });
  let [odds, setOdds] = useState();
  let [oneShoot, setOneShoot] = useState(false);
  let [rewardDesc, setRewardDesc] = useState("");
  let oddsLocal = getOfferOdds();
  const [audio] = useState(new Audio(scrachSound));

  let scratchImg = getScratchImg();

  useEffect(() => {
    if (areAllPlayed()) {
      navigate("/can-not-play");
    } else {
      getGameResult(setWinner, setAllowed, setRewardDesc);
    }

    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "game_display",
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: "e-commerce",
      },
    });
  }, []);

  useEffect(() => {
    setOdds(oddsLocal);
  }, [oddsLocal]);

  let prevOdds = usePrevious(odds);
  useEffect(() => {
    if (odds === "one" || prevOdds === "one") {
      setOneShoot(true);
    } else if (oneShoot) {
      setOneShoot(false);
    }
  }, [odds]);

  function playWithoutScratching() {
    setPlayAuto(true);
    play();
    setTimeout(() => setScreenFinished(true), REDIRECTING_TIME);
  }

  function play() {
    if (ECommerceMode()) {
      setResultGameOfferEcomm();
    } else {
      setResultGameOffer();
    }
  }

  useEffect(() => {
    if (isScreenFinished) {
      setTimeout(() => {
        if (winner && (odds === 0 || !odds)) {
          winSoundPlay();
        }
        setGameEnded(true);
      }, REDIRECTING_TIME);
    }
  }, [isScreenFinished]);

  function newGame() {
    setGameEnded(false);
    setScreenFinished(false);
  }

  function gameContent() {
    return allowed ? (
      <div className="game-content">
        <div className="game">
          {ECommerceMode() && !oneShoot && (
            <div className="subtitle odds-text">
              <img
                src={cloverImg}
                width={30}
                className="clover-game"
                alt="clover"
              />

              {t("game.oddsTitle", { nbChance: odds })}
            </div>
          )}
          {isMobile ? (
            <div className="title">{t("game.scratch.titleMobile")}</div>
          ) : (
            <div className="subtitle">{t("game.scratch.title")}</div>
          )}

          <div className="scratch-layers">
            {loaded && winner !== undefined ? (
              <ScratchMe
                width={isMobile ? 300 : 600}
                height={isMobile ? 175 : 350}
                foregroundImageSrc={
                  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/calgary-bridge-2013.jpg"
                }
                resultImage={
                  winner && (odds === 0 || !odds)
                    ? brandLogo
                      ? brandLogo
                      : winningImage
                    : losingImage
                }
                resultText={undefined}
                strokeWidth={50}
                onCompleted={() => {
                  setScreenFinished(true);
                  play();
                }}
                completedAt={60}
                audio={audio}
              />
            ) : (
              <div className="container-loading">
                <Loading />
              </div>
            )}
          </div>

          {isMobile && (
            <div className="subtitle">{t("game.scratch.titleMobile2")}</div>
          )}
          <div className="buttons-area scratch-button">
            <Button
              text={t("game.play")}
              disabled={gameEnded}
              doAction={playWithoutScratching}
            />
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  function renderedGame() {
    if (!gameEnded) {
      return (
        <SlotMachine
          content={gameContent()}
          backgroundLayerStyle={backgroundLayerStyle}
          flat
        />
      );
    } else {
      if (winner && (odds === 0 || !odds)) {
        return (
          <Win
            backgroundLayerStyle={backgroundLayerStyle}
            rewardDesc={rewardDesc}
          />
        );
      } else {
        return (
          <Lost
            newGame={newGame}
            odds={odds}
            backgroundLayerStyle={backgroundLayerStyle}
          />
        );
      }
    }
  }

  return renderedGame();
}

export default ScratchGame;
