import React, { useEffect } from "react";
import { Link, useParams } from "@reach/router";
import legalJson from "../../../../shop/legals/legal.json";
import "./assets/styles.scss";

function Legal({ token, setIsLegal, isMobile }) {
  const params = useParams();
  useEffect(() => {
    setIsLegal(true);
    return () => {
      setIsLegal(false);
    };
  }, []);

  function getLegalPageHtml(legalType) {
    switch (legalType) {
      case "help":
        return legalJson.help;
      case "generalConditions":
        return legalJson.generalConditions;
      case "legalNotices":
        return legalJson.legalNotices;
      case "dataProtection":
        return legalJson.dataProtection;
      default:
        return legalJson.help;
    }
  }
  let returnLink = `/?info=${token}`;

  const htmlPage = getLegalPageHtml(params.legalType);
  return (
    <div className="legal-container">
      <div className="legal-content">
        <Link
          className={`${isMobile ? "back-button-mobile" : "back-button"}`}
          to={returnLink}
        />
        <div className="legal-text">
          <div dangerouslySetInnerHTML={{ __html: htmlPage }} />
        </div>
      </div>
    </div>
  );
}

export default Legal;
