import React, { useEffect, useState } from "react";
import Checkbox from "../../components/Checkbox/Checkbox";
import Button from "../../components/Button/Button";
import { Link, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import config from "../../../shop/config/config.json";
import { getOfferId, getRetailerId } from "../../../utils/catalinaRequests";
import Loading from "../../components/Loading/Loading";
import { gameRedirect } from "../../../utils/utils";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import "./assets/styles.scss";
import {
  ECommerceMode,
  enableEmail,
  enableName,
} from "../../../utils/clientConfig";
import { areAllPlayed } from "../../../utils/utils";

function Landing({ loaded, backgroundLayerStyle, offerContenu }) {
  const [t] = useTranslation("message");
  const navigate = useNavigate();
  const [checkedFirst, setCheckedFirst] = useState(false);
  const [checkedSecond, setCheckedSecond] = useState(false);

  useEffect(() => {
    if (areAllPlayed()) {
      navigate("/can-not-play");
    } else {
      window.dataLayer.push({
        event: "pageview",
        page: {
          url: window.location.pathname + window.location.search,
          title: "landingpage",
          offer: getOfferId(),
          retailer: getRetailerId(),
          canal: "e-commerce",
        },
      });
    }
  }, []);

  async function navigateToPage() {
    if (ECommerceMode()) {
      if (enableEmail() || enableName()) {
        navigate("/collect-info");
      } else {
        navigate(gameRedirect(config.gameType));
      }
    } else {
      navigate("/in-store");
    }
  }

  function LandingContent() {
    return loaded ? (
      <div className="info-part">
        <div className="txt-date">
          {t("landing.gamePeriod", {
            start: offerContenu.displayDate || offerContenu.startDate,
            end: offerContenu.endDate,
          })}
        </div>
        <div className="title">
          {offerContenu.offerTitle || t("landing.title")}
        </div>
        <div className="description">
          {offerContenu.offerDescription || t("landing.description")}
        </div>

        <div className="checkbox-wrap">
          <div className="agree">
            <Checkbox checked={checkedFirst} setChecked={setCheckedFirst} />
            {t("landing.optin1")}
            {offerContenu.CGU ? (
              <a href={offerContenu.CGU} target="_blank" rel="noreferrer">
                {t("landing.conditions")}
              </a>
            ) : (
              t("landing.conditions")
            )}
          </div>

          {!config.disableSecondOptin ? (
            <div className="agree">
              <Checkbox checked={checkedSecond} setChecked={setCheckedSecond} />
              <>
                {t("landing.optin2")}{" "}
                <Link to="legal/dataProtection">
                  {t("landing.informations")}
                </Link>
              </>
            </div>
          ) : null}
        </div>
        <div className="button-area">
          <Button
            text={t("landing.play")}
            disabled={
              !checkedFirst || (!checkedSecond && !config.disableSecondOptin)
            }
            doAction={navigateToPage}
          />
        </div>
      </div>
    ) : (
      <Loading />
    );
  }

  return (
    <SlotMachine
      content={LandingContent()}
      backgroundLayerStyle={backgroundLayerStyle}
    />
  );
}

export default Landing;
