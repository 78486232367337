import React, { useEffect, useState } from "react";
import "./assets/styles.scss";
import SlotMachine from "../../../components/Slotmachine/SlotMachine";
import Confetti from "react-dom-confetti";
import shopConfig from "../../../../shop/config/config.json";
import { useTranslation } from "react-i18next";
import {
  crypteItem,
  getMemberId,
  getOfferId,
  getRetailerId,
  getRewardId,
  patchMember,
  postAttachReward,
} from "../../../../utils/catalinaRequests";
import { navigate } from "@reach/router";
import InputText from "../../../components/Form/Input/InputText";
import ShareComponent from "../../../components/Share/ShareComponent";
import {
  enableEmail,
  enableName,
  withRefundFlow,
  withDynamicReward,
  collectInfoAfter,
  ReceiptMode,
  PinMode,
} from "../../../../utils/clientConfig";
import Replay from "../../../components/Modal/Replay";
import { useForm } from "react-hook-form";
import { onEnter } from "../../../../utils/utils";
import Button from "../../../components/Button/Button";

const config = {
  angle: 90,
  spread: 360,
  startVelocity: "37",
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: "20px",
  height: "21px",
  perspective: "544px",
  colors: shopConfig.celebrationConfig.colors,
};

function Win({ backgroundLayerStyle, rewardDesc }) {
  const { t } = useTranslation("message");
  const totalTime = 300;
  const [hurray, setHurray] = useState(false);
  const [errorAPI, setErrorAPI] = useState();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "game_played",
        offer: getOfferId(),
        retailer: getRetailerId(),
        game_result: "Winner",
        canal: "e-commerce",
      },
    });
    setTimeout(() => {
      setHurray(true);
    }, totalTime);
  }, []);

  function renderWinContent() {
    return (
      <SlotMachine
        content={winContent()}
        backgroundLayerStyle={backgroundLayerStyle}
      />
    );
  }

  function redirectHandler() {
    if (withRefundFlow()) {
      window.dataLayer.push({
        event: "claim_link",
      });
      navigate("/refund");
    } else {
      navigate("/success-email");
    }
  }

  async function onSubmit(data) {
    setErrorAPI();
    let values = getValues();
    if (
      ((enableEmail() || enableName()) && collectInfoAfter()) ||
      (!enableEmail() && (ReceiptMode() || PinMode()))
    ) {
      let body = {};
      if (values.name && values.lastName) {
        body = { name: values.name, lastName: values.lastName };
      }
      if (values.email) {
        body.email = values.email;
      }
      patchMember(body, getMemberId())
        .then((resp) => {
          redirectHandler();
        })
        .catch((e) => {
          let data = {
            reward_id: getRewardId(),
            target_member_email: crypteItem(values.email),
            member_id: getMemberId(),
          };
          postAttachReward(data).catch((err) =>
            setErrorAPI(t("refund.errorRequest"))
          );
          if (withRefundFlow()) {
            window.dataLayer.push({
              event: "claim_link",
            });
            window.location.href = "/refund";
          } else {
            window.location.href = "/success-email";
          }
        });
    } else if (withRefundFlow()) {
      redirectHandler();
    } else {
      document.location = process.env.REACT_APP_HOME_SHOPPING_URL;
    }
  }

  let disabledSubmit =
    (enableEmail() || enableName()) && collectInfoAfter()
      ? !isValid || !isDirty
      : false;

  function winContent() {
    return (
      <div className="win-content">
        {!enableEmail() && !enableName() && !withRefundFlow() && <Replay />}
        <div className="confetti">
          <Confetti active={hurray} config={config} />
        </div>
        <div className="title">{shopConfig.WinMessage?.title}</div>
        <div className="subtitle">
          {withRefundFlow()
            ? t("win.descriptionRefund")
            : withDynamicReward()
            ? rewardDesc
            : shopConfig?.WinMessage?.desc}
        </div>
        {shopConfig?.enableShare &&
          !withRefundFlow() &&
          !enableEmail() &&
          !enableName() && <ShareComponent type="win" />}
        <form onSubmit={handleSubmit(onSubmit)} onKeyUp={(e) => onEnter(e)}>
          {enableName() && collectInfoAfter() && (
            <>
              <InputText
                name="firstName"
                register={register}
                watch={watch}
                required
                type="text"
                placeholder={t("landing.name")}
                autoComplete="off"
                errorMessage={t("refund.intro.error")}
              />

              <InputText
                name="lastName"
                register={register}
                watch={watch}
                required
                type="text"
                placeholder={t("landing.lastName")}
                autoComplete="off"
                errorMessage={t("refund.intro.error")}
              />
            </>
          )}
          {(enableEmail() && collectInfoAfter()) ||
          (!enableEmail() && (ReceiptMode() || PinMode())) ? (
            <>
              <InputText
                name="email"
                register={register}
                watch={watch}
                required
                type="text"
                placeholder={t("landing.email")}
                autoComplete="off"
                pattern={{
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "format incorrect",
                }}
                displayErrors={!!errorAPI?.error}
                errorMessage={errorAPI?.error || t("refund.intro.error")}
              />

              <label>{t("landing.contactInfoDesc")}</label>
            </>
          ) : null}

          <Button
            text={
              withRefundFlow() ? t("win.btn") : shopConfig?.WinMessage?.txtCTA
            }
            disabled={disabledSubmit}
            type="submit"
          />
        </form>
      </div>
    );
  }

  return renderWinContent();
}

export default Win;
