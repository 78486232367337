import React, { useEffect } from "react";
import SlotMachine from "../../components/Slotmachine/SlotMachine";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import eye from "../../../shop/assets/img/eye.png";
import lips from "../../../shop/assets/img/lips.png";
import { getOfferId, getRetailerId } from "../../../utils/catalinaRequests";
import "../../../game/resources/assets/styles/common.scss";
import config from "./../../../shop/config/config.json";

function CanNotPlay() {
  const { t } = useTranslation("message");
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: window.location.pathname + window.location.search,
        title: "cannot_play",
        offer: getOfferId(),
        retailer: getRetailerId(),
        canal: "e-commerce",
      },
    });
  }, []);

  function CanNotPlayContent() {
    return (
      <>
        <div className="content-up">
          <img src={eye} alt="" />{" "}
          <div className="title">
            {t("general.sorry")}
            <br /> {t("cannot.canNotPlay")}
          </div>
          <img src={eye} alt="" />
        </div>
        <div className="description">
          {config?.ErrorMessage?.desc
            ? config?.ErrorMessage?.desc
            : t("cannot.description")}
        </div>
        <div className="button-area">
          <Button
            text={
              config?.ErrorMessage?.txtCTA
                ? config?.ErrorMessage?.txtCTA
                : t("general.btnGoToSite")
            }
            doAction={() => {
              config.ErrorMessage.urlCTA
                ? (document.location = config?.ErrorMessage?.urlCTA)
                : (document.location = process.env.REACT_APP_HOME_SHOPPING_URL);
            }}
          />{" "}
        </div>
        <img className="lips" src={lips} alt="" />
      </>
    );
  }

  return <SlotMachine content={CanNotPlayContent()} />;
}

export default CanNotPlay;
