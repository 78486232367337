// @ts-nocheck
import React, { createRef, RefObject, useEffect } from "react";
import { DrawWheelProps, WheelCanvasProps, WheelData } from "./types";
import { WheelCanvasStyle } from "./styles";
import { clamp } from "./utils";
import variables from "../../../../../shop/assets/styles/variables.scss";

const drawWheel = (
  canvasRef: RefObject<HTMLCanvasElement>,
  data: WheelData[],
  drawWheelProps: DrawWheelProps
) => {
  const QUANTITY = data.length;
  /* eslint-disable prefer-const */
  let {
    outerBorderColor,
    outerBorderWidth,
    innerRadius,
    innerBorderColor,
    innerBorderWidth,
    radiusLineColor,
    radiusLineWidth,
    fontSize,
    perpendicularText,
    textDistance,
  } = drawWheelProps;
  /* eslint-enable prefer-const */

  outerBorderWidth *= 2;
  innerBorderWidth *= 2;
  radiusLineWidth *= 2;
  fontSize *= 2;

  const canvas = canvasRef.current;
  if (canvas?.getContext("2d")) {
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.clearRect(0, 0, 500, 500);
    ctx.strokeStyle = "transparent";
    ctx.lineWidth = 0;

    const arc = Math.PI / (QUANTITY / 2);
    const outsideRadius = canvas.width / 2 - 10;

    const clampedTextDistance = clamp(0, 100, textDistance);
    const textRadius = (outsideRadius * clampedTextDistance) / 100;

    const clampedInsideRadius = clamp(0, 100, innerRadius);
    const insideRadius = (outsideRadius * clampedInsideRadius) / 160;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    ctx.font = `bold ${fontSize}px Helvetica, Arial`;

    for (let i = 0; i < data.length; i++) {
      const angle = i * arc;
      const { style } = data[i];
      ctx.fillStyle = (style && style.backgroundColor) as string;

      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius, angle, angle + arc, false);
      ctx.arc(centerX, centerY, insideRadius, angle + arc, angle, true);
      ctx.stroke();
      ctx.fill();
      ctx.save();

      // WHEEL RADIUS LINES
      ctx.strokeStyle = variables.innerLine || "transparent";

      ctx.lineWidth = radiusLineWidth;
      for (let j = 0; j < data.length; j++) {
        const radiusAngle = j * arc;
        ctx.beginPath();
        ctx.moveTo(
          centerX + (insideRadius + 1) * Math.cos(radiusAngle),
          centerY + (insideRadius + 1) * Math.sin(radiusAngle)
        );
        ctx.lineTo(
          centerX + (outsideRadius - 1) * Math.cos(radiusAngle),
          centerY + (outsideRadius - 1) * Math.sin(radiusAngle)
        );
        ctx.closePath();
        ctx.stroke();
      }

      // first OUTER BORDER
      ctx.strokeStyle = "white";
      ctx.lineWidth = 4;
      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius - 20, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.stroke();

      // second OUTER BORDER
      ctx.strokeStyle = variables.outerRadious;
      ctx.lineWidth = 13;
      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius - 10, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.stroke();

      // third OUTER BORDER
      ctx.strokeStyle = variables.lightPrimary;
      ctx.lineWidth = 5;
      ctx.beginPath();
      ctx.arc(centerX, centerY, outsideRadius - 1, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.stroke();

      // TEXT FILL

      const textRotationAngle = perpendicularText
        ? angle + arc / 2 + Math.PI / 2
        : angle + arc / 2;
      //FILL IMAGE
      const text = data[i].text;
      const img = new Image();
      let imgSrc = data[i].img;

      if (data[i].img) {
        // ctx.rotate(textRotationAngle);
        img.onload = function () {
          ctx.save();

          // move to the center of the canvas
          ctx.translate(
            centerX + Math.cos(angle + arc / 2) * textRadius,
            centerY + Math.sin(angle + arc / 2) * textRadius
          );
          ctx.rotate(textRotationAngle);

          // draw the image
          // since the context is rotated, the image will be rotated also
          ctx.drawImage(img, -48, -48, 96, 96);

          // we’re done with the rotating so restore the unrotated context
          ctx.restore();
          // ctx.drawImage(img, -50, -20, canvas.width / 6, canvas.height / 15);
        };

        img.src = imgSrc as unknown as string;
        ctx.restore();
      } else if (text) {
        ctx.fillStyle = (style && style.textColor) as string;

        ctx.translate(
          centerX + Math.cos(angle + arc / 2) * textRadius,
          centerY + Math.sin(angle + arc / 2) * textRadius
        );

        ctx.rotate(textRotationAngle);

        text &&
          ctx.fillText(
            text.toUpperCase(),
            -ctx.measureText(text).width / 2,
            fontSize / 2.7
          );
      }
      ctx.restore();
    }
  }
};

const WheelCanvas = ({
  width,
  height,
  data,
  outerBorderColor,
  outerBorderWidth,
  innerImage,
  innerRadius,
  innerBorderColor,
  innerBorderWidth,
  radiusLineColor,
  radiusLineWidth,
  fontSize,
  perpendicularText,
  textDistance,
  sizeWheel,
}: WheelCanvasProps): JSX.Element => {
  const canvasRef = createRef<HTMLCanvasElement>();
  let drawWheelProps = {
    outerBorderColor,
    outerBorderWidth,
    innerImage,
    innerRadius,
    innerBorderColor,
    innerBorderWidth,
    radiusLineColor,
    radiusLineWidth,
    fontSize,
    perpendicularText,
    textDistance,
  };

  useEffect(() => {
    drawWheel(canvasRef, data, drawWheelProps);
  }, [canvasRef, data, drawWheelProps]);

  return (
    <WheelCanvasStyle
      ref={canvasRef}
      width={width}
      height={height}
      sizeWheel={sizeWheel}
    />
  );
};

export default WheelCanvas;
